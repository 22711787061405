ay<template>
  <div class="billing-addresses-container">
    <!-- <pre>
      {{ fiscalPersons }}
    </pre> -->

    <v-row>
      <v-col class="hidden-sm-and-down MenuNavigator" cols="0" md="3">
        <MenuNavigator class="menu-navigator"></MenuNavigator>
      </v-col>
      <v-col cols="12" md="9">
        <h2 class="primary--text text--darken-2 pb-4">
          {{ $t("paymentInvoice.title") }}
        </h2>
        <v-expansion-panels
          v-if="fiscalPersons && fiscalPersons.length > 0"
          popout
          focusable
        >
          <v-expansion-panel
            v-for="fiscalPerson in fiscalPersons"
            :key="fiscalPerson.personFiscalId"
          >
            <v-expansion-panel-header>{{
              accordionHeader(fiscalPerson)
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="mt-3 mb-1">
                <div v-if="fiscalPerson.personTypeId === '1'">
                  <div class="vertical-table">
                    <div class="row">
                      <div class="cell header">Nome:</div>
                      <div class="cell value">
                        {{ fiscalPerson.firstName }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="cell header">Cognome:</div>
                      <div class="cell value">
                        {{ fiscalPerson.lastName }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="cell header">Codice Fiscale:</div>
                      <div class="cell value">
                        {{ fiscalPerson.vatFiscalCode }}
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class="cell header">Identificativo indirizzo:</div>
                      <div class="cell value">
                        {{ fiscalPerson.addressName }}
                      </div>
                    </div> -->
                    <div class="row">
                      <div class="cell header">Indirizzo:</div>
                      <div class="cell value">{{ fiscalPerson.address }}</div>
                    </div>
                    <div class="row">
                      <div class="cell header">Civico:</div>
                      <div class="cell value">
                        {{ fiscalPerson.addressNumber }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="cell header">Cap:</div>
                      <div class="cell value">
                        {{ fiscalPerson.postalcode }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="cell header">Comune:</div>
                      <div class="cell value">{{ fiscalPerson.city }}</div>
                    </div>
                    <div class="row">
                      <div class="cell header">Provincia:</div>
                      <div class="cell value">
                        {{ fiscalPerson.province }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="vertical-table">
                    <div class="row">
                      <div class="cell header">Ragione sociale:</div>
                      <div class="cell value">{{ fiscalPerson.company }}</div>
                    </div>
                    <div class="row" v-if="fiscalPerson.personTypeId === '3'">
                      <div class="cell header">Codice Fiscale:</div>
                      <div class="cell value">
                        {{ fiscalPerson.vatFiscalCode }}
                      </div>
                    </div>
                    <div class="row" v-else>
                      <div class="cell header">Codice Fiscale Azienda:</div>
                      <div class="cell value">
                        {{ fiscalPerson.vatFiscalCode }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="cell header">Partita IVA:</div>
                      <div class="cell value">{{ fiscalPerson.vatCode }}</div>
                    </div>
                    <!-- <div class="row">
                      <div class="cell header">Identificativo indirizzo:</div>
                      <div class="cell value">
                        {{ fiscalPerson.addressName }}
                      </div>
                    </div> -->
                    <div class="row">
                      <div class="cell header">Indirizzo:</div>
                      <div class="cell value">{{ fiscalPerson.address }}</div>
                    </div>
                    <div class="row">
                      <div class="cell header">Civico:</div>
                      <div class="cell value">
                        {{ fiscalPerson.addressNumber }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="cell header">Cap:</div>
                      <div class="cell value">
                        {{ fiscalPerson.postalcode }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="cell header">Comune:</div>
                      <div class="cell value">{{ fiscalPerson.city }}</div>
                    </div>
                    <div class="row">
                      <div class="cell header">Provincia:</div>
                      <div class="cell value">
                        {{ fiscalPerson.province }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="cell header">Nazione:</div>
                      <div class="cell value">Italia</div>
                    </div>
                  </div>
                </div>
                <v-btn
                  class="delete-person-fiscal-btn mt-3"
                  color="secondary"
                  depressed
                  :disabled="!fiscalPerson"
                  @click="deletePersonFiscal(fiscalPerson.personFiscalId)"
                >
                  <v-icon size="20" class="mr-1">$delete</v-icon>
                  {{ $t("paymentInvoice.deleteAddressBtn") }}
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <h4 v-else>
          {{ $t("paymentInvoice.noResultsFound") }}
        </h4>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped lang="scss">
.billing-addresses-container {
  .invoice-title {
    font-size: 31px;
    font-weight: normal;
  }
  .vertical-table {
    display: table;
    width: 100%;
    border-collapse: collapse;
  }
  .vertical-table .row {
    display: table-row;
  }
  .vertical-table .cell {
    display: table-cell;
    padding: 8px;
    border: 1px solid #ddd;
  }
  .vertical-table .header {
    font-weight: bold;
    width: 30%;
    background-color: #f2f2f2;
  }
  .vertical-table .value {
    width: 70%;
  }
  @media (max-width: 600px) {
    .vertical-table {
      display: block;
    }
    .vertical-table .row {
      display: block;
      margin-bottom: 10px;
    }
    .vertical-table .cell {
      display: block;
      width: 100%;
      box-sizing: border-box;
    }
    .vertical-table .header {
      background-color: #f2f2f2;
    }
  }
}
</style>

<script>
import userService from "~/service/userService";
import MenuNavigator from "@/components/profile/MenuNavigator.vue";
export default {
  name: "BillingAddresses",
  data() {
    return {
      fiscalPersons: null
    };
  },
  components: { MenuNavigator },
  methods: {
    accordionHeader(fp) {
      if (fp.personTypeId === "1") {
        return `${fp.firstName} ${fp.lastName}`;
      }
      return `${fp.company}`;
    },
    async getFiscalPersons() {
      let res = await userService.getFiscalsPersonsList();
      if (res?.personFiscals) {
        this.fiscalPersons = res.personFiscals;
      }
    },
    async deletePersonFiscal(personFiscalId) {
      await userService.deleteFiscalPerson(personFiscalId);
      await this.getFiscalPersons();
    }
  },
  async created() {
    await this.getFiscalPersons();
  }
};
</script>
