<template>
  <div class="products">
    <Breadcrumb :items="breadcrumb" class="d-flex" />

    <category-block
      :target="category"
      position="position1"
      :category-id="category.categoryId"
      class="category-block category-block-1"
    />

    <v-container
      :class="{
        'd-none': $ebsn.meta(category, 'metaData.category_info.HIDE_TITLE')
      }"
    >
      <category-title v-if="category" :category="category" />
    </v-container>
    <v-container fluid>
      <div class="product-list-swiper-container">
        <div
          v-if="category.children.length > 1"
          id="product-list-slider-prev-subcategories"
          class="swiper-button-prev"
        />
        <swiper :options="swiperOption" ref="swiperRef">
          <swiper-slide
            v-for="subCategory in category.children"
            :key="subCategory.id"
          >
            <v-card
              class="

              proposal-card
              d-flex
              flex-column
              align-center
            "
              rounded="sm"
              flat
              :to="{
                name: 'Category',
                params: { pathMatch: subCategory.slug }
              }"
            >
              <v-img
                eager
                :src="
                  subCategory.metaData.category_info.THUMB
                    ? subCategory.metaData.category_info.THUMB
                    : `${publicPath}imgs/no-image-grey.png`
                "
                :alt="subCategory.name"
                width="170"
                height="170"
              />
              <v-card-text
                class="pre-slide-text align-center"
                v-html="subCategory.name"
              ></v-card-text>
            </v-card>
          </swiper-slide>
        </swiper>
        <div
          v-if="category.children.length > 1"
          id="product-list-slider-next-subcategories"
          class="swiper-button-next"
        />
      </div>
    </v-container>

    <!-- <div class="white product-slider-wrapper"> -->
    <v-container fluid>
      <ProductListSlider
        :layout="25"
        :categoryId="category.categoryId"
        :fetchByCategory="true"
        :promo="true"
        :title="'In promozione in ' + category.name"
        :paginationClass="'swiper-pagination-promo'"
        :showArrows="$vuetify.breakpoint.smAndUp"
      />
    </v-container>
    <div class="mt-4">
      <v-container fluid>
        <ProductListSlider
          :layout="27"
          :categoryId="category.categoryId"
          :title="'I più venduti in ' + category.name"
          :paginationClass="'swiper-pagination-venduti'"
          :showArrows="$vuetify.breakpoint.smAndUp"
        >
        </ProductListSlider>
      </v-container>
    </div>

    <category-block
      :target="category"
      position="product1"
      :category-id="category.categoryId"
      class="category-block category-block-product1"
    />
    <category-block
      :target="category"
      position="product2"
      :category-id="category.categoryId"
      class="category-block category-block-product2"
    />
    <category-block
      :target="category"
      position="position2"
      :category-id="category.categoryId"
      class="category-block category-block-2"
    />

    <div class="white" v-if="footerText">
      <v-container><div v-html="footerText"/></v-container>
    </div>
  </div>
</template>
<style scoped lang="scss">
.product-list-swiper-container {
  position: relative;
}
.proposal-card {
  background: transparent;
}
.pre-slide-text {
  width: initial;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: $primary !important;
}
</style>
<script>
import ProductListSlider from "@/components/product/ProductListSlider";
import Breadcrumb from "@/components/navigation/Breadcrumb";
import CategoryTitle from "@/components/category/CategoryTitle";

import categoryMixins from "~/mixins/category";

export default {
  name: "CategoryMain",
  mixins: [categoryMixins],
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        slidesPerGroup: 1,
        spaceBetween: this.$vuetify.breakpoint.xs ? 0 : 24,
        // spaceBetween: 0,
        watchOverflow: true,
        // pagination: {
        //   el: "." + this.paginationClass,
        //   clickable: true
        // },
        navigation: {
          prevEl: "#product-list-slider-prev-subcategories",
          nextEl: "#product-list-slider-next-subcategories"
        },
        breakpoints: {
          320: {
            slidesPerView: 2,
            centeredSlides: false
          },
          600: {
            slidesPerView: 3,
            centeredSlides: false
          },
          960: {
            slidesPerView: 5,
            slidesPerGroup: 5,
            centeredSlides: false
          },
          1280: {
            slidesPerView: 5,
            slidesPerGroup: 5,
            centeredSlides: false
          },
          1590: {
            slidesPerView: 5,
            slidesPerGroup: 5,
            centeredSlides: false
          }
        }
      }
    };
  },
  components: {
    ProductListSlider,
    Breadcrumb,
    CategoryTitle
  },
  computed: {
    footerText() {
      return this.$ebsn.meta(
        this.category,
        "metaData.category_info.FOOTER_TEXT",
        null
      );
    }
  },
  metaInfo() {
    return {
      title: this.$ebsn.meta(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.category ? this.category.name : ""
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.$ebsn.meta(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.firstLevel.description", [
              this.category.name
            ])
          )
        }
      ]
    };
  }
};
</script>
