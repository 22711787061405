<template>
  <div
    class="payment-invoice flex-column"
    v-if="
      order.orderInfos &&
        order.orderInfos.need_invoice == 'true' &&
        order.orderInfos.PERSON_FISCAL_ID &&
        fiscalPerson
    "
  >
    <v-card class="payment-invoice-card pa-6 mb-5" :loading="loading">
      <h2 class="invoice-title primary--text text--darken-2 pb-4">
        Informazioni di fatturazione
      </h2>
      <div
        class="my-3"
        v-if="order.orderInfos.compile_invoice_form != 'true'"
        v-html="$t('order.orderInvoiceFormNotFound')"
      ></div>
      <div v-if="fiscalPerson.personTypeId === '1'">
        <div class="vertical-table">
          <div class="row">
            <div class="cell header">Nome:</div>
            <div class="cell value">{{ fiscalPerson.firstName }}</div>
          </div>
          <div class="row">
            <div class="cell header">Cognome:</div>
            <div class="cell value">{{ fiscalPerson.lastName }}</div>
          </div>
          <div class="row">
            <div class="cell header">Codice Fiscale:</div>
            <div class="cell value">{{ fiscalPerson.vatFiscalCode }}</div>
          </div>
          <!-- <div class="row">
            <div class="cell header">Identificativo indirizzo:</div>
            <div class="cell value">{{ fiscalPerson.addressName }}</div>
          </div> -->
          <div class="row">
            <div class="cell header">Indirizzo:</div>
            <div class="cell value">{{ fiscalPerson.address }}</div>
          </div>
          <div class="row">
            <div class="cell header">Civico:</div>
            <div class="cell value">{{ fiscalPerson.addressNumber }}</div>
          </div>
          <div class="row">
            <div class="cell header">Cap:</div>
            <div class="cell value">{{ fiscalPerson.postalcode }}</div>
          </div>
          <div class="row">
            <div class="cell header">Comune:</div>
            <div class="cell value">{{ fiscalPerson.city }}</div>
          </div>
          <div class="row">
            <div class="cell header">Provincia:</div>
            <div class="cell value">{{ fiscalPerson.province }}</div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="vertical-table">
          <div class="row">
            <div class="cell header">Ragione sociale:</div>
            <div class="cell value">{{ fiscalPerson.company }}</div>
          </div>
          <div class="row" v-if="fiscalPerson.personTypeId === '3'">
            <div class="cell header">Codice Fiscale:</div>
            <div class="cell value">{{ fiscalPerson.vatFiscalCode }}</div>
          </div>
          <div class="row" v-else>
            <div class="cell header">Codice Fiscale Azienda:</div>
            <div class="cell value">{{ fiscalPerson.vatFiscalCode }}</div>
          </div>
          <div class="row">
            <div class="cell header">Partita IVA:</div>
            <div class="cell value">{{ fiscalPerson.vatCode }}</div>
          </div>
          <!-- <div class="row">
            <div class="cell header">Identificativo indirizzo:</div>
            <div class="cell value">{{ fiscalPerson.addressName }}</div>
          </div> -->
          <div class="row">
            <div class="cell header">Indirizzo:</div>
            <div class="cell value">{{ fiscalPerson.address }}</div>
          </div>
          <div class="row">
            <div class="cell header">Civico:</div>
            <div class="cell value">{{ fiscalPerson.addressNumber }}</div>
          </div>
          <div class="row">
            <div class="cell header">Cap:</div>
            <div class="cell value">{{ fiscalPerson.postalcode }}</div>
          </div>
          <div class="row">
            <div class="cell header">Comune:</div>
            <div class="cell value">{{ fiscalPerson.city }}</div>
          </div>
          <div class="row">
            <div class="cell header">Provincia:</div>
            <div class="cell value">{{ fiscalPerson.province }}</div>
          </div>
          <div class="row">
            <div class="cell header">Nazione:</div>
            <div class="cell value">Italia</div>
          </div>
        </div>
      </div>
      <!-- <pre>{{ fiscalPerson }}</pre> -->
    </v-card>
  </div>
</template>
<style lang="scss">
.vertical-table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}
.vertical-table .row {
  display: table-row;
}
.vertical-table .cell {
  display: table-cell;
  padding: 8px;
  border: 1px solid #ddd;
}
.vertical-table .header {
  font-weight: bold;
  width: 30%;
  background-color: #f2f2f2;
}
.vertical-table .value {
  width: 70%;
}
@media (max-width: 600px) {
  .vertical-table {
    display: block;
  }
  .vertical-table .row {
    display: block;
    margin-bottom: 10px;
  }
  .vertical-table .cell {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }
  .vertical-table .header {
    background-color: #f2f2f2;
  }
}
</style>
<script>
import { mapActions, mapGetters, mapState } from "vuex";

import RegistrationService from "~/service/userService";
import { req } from "../../validator/commons";
import { mapCartInfo } from "~/service/ebsn";
import {
  requiredValue,
  isEmail,
  isCF,
  isCfIva,
  isMobilePhoneStrict
} from "@/validator/validationRules";

export default {
  name: "OrderInvoice",
  props: {
    order: { type: Object, required: true }
  },
  data() {
    return {
      customCountries: global.config.customCountries,
      loading: false,
      valid: false,
      validPP: false,
      lazy: false,
      dirty: false,
      address: null,
      isMobilePhoneRules: [isMobilePhoneStrict(), requiredValue()],
      phoneConfirmed: false,
      billingData: {},
      billingDataPhone: {
        phone: null,
        prefix: "+39"
      },
      requiredValue: requiredValue,
      cfRules: [requiredValue(), isCF()],
      emailPersonRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      emailRules: [
        (this.billingData && !this.billingData.person.emailCertified) ||
          isEmail()
      ],
      emailPecRules: [
        v => {
          if (v) {
            return (
              !req(v) ||
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) ||
              "Digitare una e-mail valida"
            );
          } else if (this.billingData && !this.billingData.person.vatSdiCode) {
            return "PEC o SDI obbligatori";
          } else {
            return true;
          }
        }
      ],
      sdiRules: [
        v => {
          if (v) {
            return /^[A-Z0-9]{6,7}$/.test(v) || "Codice SDI non valido";
          } else if (
            this.billingData &&
            !this.billingData.person.emailCertified
          ) {
            return "PEC o SDI obbligatori";
          } else {
            return true;
          }
        }
      ],
      cfIvaRules: [requiredValue(), isCfIva()],
      selectedProvince: null,
      selectedCity: null,
      selectedAddress: null,
      city: null,
      address1: null,
      fiscalPerson: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapCartInfo({ sellCondition: "accept_sell_condition" }),
    ...mapGetters({
      needInvoice: "cart/needInvoice",
      warehouseId: "cart/getWarehouseId",
      serviceId: "cart/getSelectedService"
    }),
    disabledUpdate() {
      return !this.valid;
    }
  },
  methods: {
    async getUserDetail() {
      let _this = this;
      let res = await RegistrationService.getUserDetail();
      _this.billingData = res;
      if (res.phone && res.phone.length > 0) {
        this.phoneConfirmed = true;
      } else {
        this.phoneConfirmed = false;
      }
      this.billingData = res;
      this.billingData.prefix = "+39";
      this.billingDataPhone.phone = this.billingData.phone;
    },
    async getFiscalPerson() {
      let res = await RegistrationService.getFiscalsPersonsList();
      if (res?.personFiscals) {
        const filteredPF = res.personFiscals.filter(
          pf => pf.personFiscalId == this.order.orderInfos.PERSON_FISCAL_ID
        );
        if (filteredPF.length) {
          this.fiscalPerson = filteredPF[0];
        }
      }
    },

    ...mapActions({
      setCartInfo: "cart/setCartInfo"
    })
  },
  async mounted() {
    await this.getUserDetail();
    await this.getFiscalPerson();
    if (this.needInvoice) {
      this.validate();
    }
    if (typeof this.sellCondition === "undefined") {
      this.sellCondition = "false";
    }
  }
};
</script>
