<template>
  <div
    class="payment-type payment-type-delivery"
    data-test="payment-type-delivery"
  >
    <div
      class="payment-type-title"
      v-html="paymentType.title"
      v-if="paymentType.title"
    ></div>

    <div
      style="color: var(--v-primary-base);"
      class="payment-type-description"
      v-html="paymentType.descr"
    ></div>

    <!-- <div class="custom-warning">
      <v-alert type="warning" title="ATTENZIONE" class="wrn">
        ATTENZIONE<br />
        Per ordini superiori a
        <strong>{{ $n(75, "currency") }}</strong
        >, all'importo totale aggiungere
        <strong>{{ $n(2, "currency") }}</strong>
        di marca da bollo.
      </v-alert>
    </div> -->

    <v-alert
      v-if="error"
      type="error"
      border="left"
      transition="v-fab-transition"
    >
      {{ error }}
    </v-alert>
    <div
      v-if="$te('paymentTypeList.redirectText.' + paymentType.paymentTypeId)"
      v-html="$t('paymentTypeList.redirectText.' + paymentType.paymentTypeId)"
      class="redirect-text"
    ></div>
    <ResponseMessage :response="response" />
    <v-row class="d-flex justify-end">
      <v-col
        cols="12"
        md="6"
        class="d-flex align-center"
        v-if="mode != 'order' && !options.hidePrevButton"
      >
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-end">
        <v-btn
          v-if="options.confirm.show"
          :color="options.confirm.color"
          :outlined="options.confirm.outline"
          class="mt-3 mt-sm-0 ml-2 gateway-btn"
          :depressed="options.confirm.depressed"
          :large="options.confirm.large"
          :class="options.confirm.class"
          v-on:click="buy"
        >
          {{ options.confirm.label }}
        </v-btn>
      </v-col>
    </v-row>
    <i18n
      v-if="$te(`paymentTypeList.${paymentType.gatewayClient}.caption`)"
      :path="`paymentTypeList.${paymentType.gatewayClient}.caption`"
      tag="div"
      class="caption"
    />
  </div>
</template>
<style>
.custom-warning .theme--dark.v-icon {
  color: red !important;
}
.custom-warning .wrn {
  background-color: white !important;
  color: red;
  border-color: var(--v-warning-base) !important;
  border-width: 2px;
  border-style: solid;
}
</style>
<script>
import ResponseMessage from "@/components/ResponseMessage";
//import OrderService from "~/service/orderService";
import gateway from "~/mixins/gateway";
import SmartcartService from "~/service/smartcartService";

import { mapActions } from "vuex";
var vm;

export default {
  name: "PaymentTypeDeliveryGiftCardAli",
  mixins: [gateway],
  props: {
    smartCart: { type: Object, required: false }
  },
  components: { ResponseMessage },
  data() {
    return { loading: false, error: null, response: {} };
  },
  methods: {
    ...mapActions({
      buyCart: "cart/buyCart"
    }),

    actionPreBuy() {
      console.log("actionPreBuy");
      SmartcartService.resetProds(this.smartCart.store.storeId);
      SmartcartService.addProductsToList(
        this.smartCart,
        this.smartCart.store.storeId
      );
    },
    async buy() {
      // UPDATE CART INFO AND PAY
      vm.loading = true;

      try {
        // vm.actionPreBuy();

        let dataRaw = await SmartcartService.confirm(
          this.smartCart.store.storeId,
          this.smartCart.shippingAddress.addressId,
          this.paymentType.paymentTypeId
        );
        console.log(dataRaw);
        let response = dataRaw;

        let orderId = "/-1";
        if (
          response &&
          response.data &&
          response.data.order &&
          response.data.order.orderId
        ) {
          orderId = "/" + response.data.order.orderId;
        }

        if (response) {
          global.EventBus.$emit("pay-response-ok", response);
          //this
          this.$router.push("/cart/payment-completed-giftcard" + orderId);
        } else {
          this.$router.push("/cart/payment-error-giftcard" + orderId);
        }
      } catch (err) {
        vm.response = err;
      } finally {
        vm.loading = false;
      }
    },
    goBack() {
      this.$router.push("/checkout");
    }
  },
  created() {
    vm = this;
  }
};
</script>
