<template>
  <v-tooltip left v-if="limit > 0" max-width="200">
    <template v-slot:activator="{ on, attrs }">
      <div class="max-acq" v-bind="attrs" v-on="on">
        <span>{{ limit }} pz tot</span>
      </div>
    </template>
    <span v-text="tooltip"> </span>
  </v-tooltip>
</template>
<style scoped lang="scss">
.max-acq {
  width: 36px;
  max-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  margin-top: -8px;
  span {
    text-transform: uppercase;
    padding: 2px 2px;
    color: #ffffff;
    line-height: 1;
    background-color: $primary;
    font-size: 10px;
    font-weight: bold;
    display: flex;
    text-align: center;
    border-radius: $border-radius-root;
    justify-content: center;
    align-items: center;
  }
}
</style>
<script>
import get from "lodash/get";
export default {
  name: "ProductAcqLimit",
  props: ["product"],
  data() {
    return {
      useProductClassRuleMax: false
    };
  },
  computed: {
    limit() {
      let max = get(this.product, "productClassRuleMax.max");
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.useProductClassRuleMax = !(max > this.product.maxAcq);
      let maxAcq = this.useProductClassRuleMax ? max : this.product.maxAcq;
      let multiPack = get(this.product, "productInfos.MULTI_PACK", 1);
      if (this.useProductClassRuleMax) {
        return this.product.productClassRuleMax?.max;
      } else {
        return maxAcq / multiPack;
      }
    },
    tooltip() {
      if (this.useProductClassRuleMax) {
        return this.$t(
          "product.maxAcqClassTooltip",
          this.product.productClassRuleMax
        );
      } else {
        return this.$t("product.maxAcqTooltip", { count: this.limit });
      }
    }
  }
};
</script>
