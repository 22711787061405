var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-detail d-flex flex-column scroll-container"},[(_vm.order)?_c('v-container',{staticClass:"d-flex flex-column scroll-container",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"hidden-sm-and-down MenuNavigator",attrs:{"cols":"0","md":"3"}},[_c('MenuNavigator',{staticClass:"menu-navigator"})],1),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('ProfileTitle',{attrs:{"category":_vm.category}}),_c('OrderDetailInfo',{staticClass:"mb-4",attrs:{"order":_vm.order},on:{"addAllToCart":_vm.addAllToCart,"deleteOrder":_vm.deleteOrder,"editOrder":_vm.editOrder,"showPayment":function($event){_vm.showPayment = !_vm.showPayment}}}),_c('OrderInvoice',{staticClass:"mt-4",attrs:{"order":_vm.order}}),(_vm.showPayment)?_c('PaymentTypeList',{attrs:{"orderId":_vm.order.orderId,"options":{
              showRememberCard: false,
              showPaymentMethodImage: true,
              showCardLogos: true,
              orderAmount: _vm.cart.totalPrice,
              showPrebilledAmountInfoText: true,
              showPrebilledAmountHtml: true,
              showAddNewCardWarning: false,
              confirm: { color: 'secondary' },
              back: {
                class: 'back-btn'
              },
              titleStyle:
                'primary--text text--darken-2 text-h5 px-4 font-weight-bold mb-2'
            }}}):_vm._e(),_c('OrderDetailTable',{attrs:{"items":_vm.itemList,"delivered":_vm.delivered,"order":_vm.order}}),_c('v-row',{staticClass:"actions-buttons footer-btn justify-center"},[_c('v-btn',{staticClass:"bg-white",attrs:{"outlined":"","color":"primary","x-large":"","depressed":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.historyBack.apply(null, arguments)}}},[_vm._v(" TORNA AI MIEI ORDINI ")])],1)],1)],1)],1):_vm._e(),_c('v-spacer',{staticClass:"spacer-scroll"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }