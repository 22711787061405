<template>
  <v-container
    class="payment-type payment-type-nexi ticket-type-restaurant stripe-payment"
  >
    <v-row no-gutters>
      <v-col
        class="summary-cards d-flex flex-column mt-4 mt-sm-0"
        cols="12"
        md="3"
        order="0"
        order-md="1"
      >
        <div class="filter-sticky-column">
          <CartInfoAddressCard
            v-if="order"
            class="mb-2 order-1 order-md-0"
            :shippingAddress="order.shippingAddress"
            :editable="false"
            @edit="openAddressSelector"
            :isCheckout="false"
            :small="true"
          />

          <CartInfoTimeslotCard
            v-if="order"
            class="mb-2 order-2 order-md-1"
            :shippingAddress="order.shippingAddress"
            :timeslot="order.timeslot"
            :editable="false"
            @edit="openTimeslotSelector"
            :isCheckout="false"
            :small="true"
          />

          <CheckoutSummary
            v-if="order"
            class="order-0 order-md-2 mb-6"
            :orderCart="order"
            :stripePage="true"
          />
        </div>
      </v-col>
      <v-col
        cols="12"
        order="1"
        order-md="0"
        md="9"
        class="cart-info pa-0 pr-sm-0 pr-md-9"
      >
        <v-card class="order-info pa-4">
          <v-row class="d-flex">
            <v-col cols="8" sm="8" md="4">
              <span class="text-body-2"><strong>N° Ordine:</strong></span>
            </v-col>
            <v-col
              cols="4"
              sm="4"
              md="8"
              :class="$vuetify.breakpoint.smAndDown ? 'd-flex justify-end' : ''"
            >
              <span
                class="text-body-2"
                :style="
                  $vuetify.breakpoint.smAndDown ? 'align-content: flex-end' : ''
                "
                >#{{ this.$route.params.order }}</span
              >
            </v-col>
          </v-row>
          <v-row class="d-flex">
            <v-col cols="8" sm="8" md="4">
              <span class="text-body-2"
                ><strong>TOTALE (pre-autorizzato):</strong></span
              >
            </v-col>
            <v-col
              cols="4"
              sm="4"
              md="8"
              :class="$vuetify.breakpoint.smAndDown ? 'd-flex justify-end' : ''"
            >
              <span
                class="text-body-2"
                :style="
                  $vuetify.breakpoint.smAndDown ? 'align-content: flex-end' : ''
                "
                >{{ $n(this.$route.params.orderGrossTotal, "currency") }}</span
              >
            </v-col>
          </v-row>
          <v-row class="d-flex">
            <v-col cols="8" sm="8" md="4"
              ><span class="text-body-2"
                ><strong>Data ritiro/consegna:</strong></span
              ></v-col
            >
            <v-col
              cols="4"
              sm="4"
              md="8"
              :class="$vuetify.breakpoint.smAndDown ? 'd-flex justify-end' : ''"
            >
              <span
                class="text-body-2"
                :style="
                  $vuetify.breakpoint.smAndDown ? 'align-content: flex-end' : ''
                "
                >{{
                  $vuetify.breakpoint.xsOnly
                    ? $dayjs(
                        this.$route.params.timeSlotDate,
                        "YYYY-MM-DD"
                      ).format("D/MM/YYYY")
                    : $dayjs(
                        this.$route.params.timeSlotDate,
                        "YYYY-MM-DD"
                      ).format("D MMMM YYYY")
                }}</span
              >
            </v-col>
          </v-row>
          <v-row class="d-flex">
            <v-col cols="8" sm="8" md="4"
              ><span class="text-body-2"
                ><strong>Fascia ritiro/consegna:</strong></span
              ></v-col
            >
            <v-col
              cols="4"
              sm="4"
              md="8"
              :class="$vuetify.breakpoint.smAndDown ? 'd-flex justify-end' : ''"
            >
              <span
                class="text-body-2"
                :style="
                  $vuetify.breakpoint.smAndDown ? 'align-content: flex-end' : ''
                "
                >{{ timeslotValue }}</span
              >
            </v-col>
          </v-row>
        </v-card>

        <div class="mb-3 mt-8" v-if="data.showSinglePayment">
          <h2 class="primary--text">Come vuoi pagare?</h2>
        </div>

        <div v-if="data.showSinglePayment">
          <stripe-element-payment
            ref="paymentRef"
            :pk="pk"
            :elements-options="elementsOptions"
            :confirm-params="confirmParams"
          />
          <br />
          <v-row>
            <v-col cols="6">
              <v-btn
                class="stripe-confirm back-button px-0 px-md-5"
                color="grey-darken2"
                outlined
                depressed
                x-large
                @click="goBack()"
                >INDIETRO</v-btn
              >
            </v-col>
            <v-col cols="6">
              <v-btn
                class="secondary stripe-confirm"
                color="grey-darken2"
                outlined
                depressed
                x-large
                @click="pay"
                >CONFERMA ORDINE</v-btn
              >
            </v-col>
          </v-row>
          <!-- <v-btn
            v-else
            class="secondary stripe-confirm"
            outlined
            depressed
            x-large
            @click="pay"
            >CONFERMA ORDINE</v-btn
          > -->
        </div>

        <div v-if="!data.showSinglePayment">
          <PaymentAuthList
            :paymentTypeId="25"
            :showRemoveCard="false"
            :filterByUserPaymentAuthId="userPaymentAuthId"
            :showAddNewCardWarning="false"
          />
          <br />
          <button @click="redirectRecurrent">
            Paga Ora con Carta Selezionata
          </button>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped lang="scss">
.stripe-confirm {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    max-width: fit-content;
    float: right;
    font-size: 15px;
  }
  font-size: 13px;
  color: #333333;
}
.back-button {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    max-width: fit-content;
    float: left;
  }
  font-size: 15px;
  color: #333333;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: 13px !important;
  }
}
</style>

<script>
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import PaymentAuthList from "~/components/payment/gateways/PaymentAuthList.vue";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";
import OrderService from "~/service/orderService";

import { mapState } from "vuex";

export default {
  components: {
    StripeElementPayment,
    //   StripeElementCard,
    PaymentAuthList,
    CheckoutSummary,
    CartInfoAddressCard,
    CartInfoTimeslotCard
  },
  mixins: [cartInfo, categoryMixins],
  data() {
    return {
      selectedPaymentAuthId: null,
      rememberNewCard: false,
      userPaymentAuthId: this.$route.params.payment_auth_id
        ? parseInt(this.$route.params.payment_auth_id)
        : null,
      pk: this.$route.params.payment_data,
      elementsOptions: {
        appearance: {} // appearance options
      },
      data: {
        showSinglePayment: true,
        pk: this.$route.params.payment_data,
        userPaymentAuthId: this.$route.params.payment_auth_id
          ? parseInt(this.$route.params.payment_auth_id)
          : null
      },
      confirmParams: {
        /*return_url:
          process.env.VUE_APP_EBSN_URL +
          "/ebsn/api/stripe/payment-feedback?&id=" +
          this.$route.params.id*/
        //return_url: 'http://localhost:8080'+'/ebsn/api/stripe/payment-feedback?&id='+this.$route.params.id,
        return_url:
          `${process.env.VUE_APP_EBSN_URL}ebsn/api/stripe/payment-feedback?&id=` +
          this.$route.params.id
      },
      stripeOptions: {},
      order: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    timeslotValue() {
      if (this.order.timeslot) {
        return (
          this.$dayjs(this.order.timeslot.beginTime, [
            "h:mm:ss A",
            "H:mm"
          ]).format("HH:mm") +
          " - " +
          this.$dayjs(this.order.timeslot.endTime, [
            "h:mm:ss A",
            "H:mm"
          ]).format("HH:mm")
        );
      }
      return this.$t("navbar.noTimeslot");
    }
  },
  async mounted() {
    if (this.$route.params.payment_auth_id) {
      this.data.showSinglePayment = false;
    } else {
      this.data.showSinglePayment = true;
    }

    this.preparePaymentIntent();

    let _this = this;

    console.log("bbbb", _this.$route);
  },
  async created() {
    let _this = this;
    console.log("bbbb", _this.$route);
    this.order = await OrderService.getOrder(_this.$route.params.order, true);
  },
  methods: {
    goBack() {
      this.$router.push("/checkout");
    },
    async preparePaymentIntent() {
      this.elementsOptions.clientSecret = this.$route.params.payment_client_secret;
    },

    async redirectRecurrent() {
      if (this.$route.params.redirect_url_recurrent) {
        this.data.showSinglePayment = false;
        this.loading = true;
        window.location = this.$route.params.redirect_url_recurrent;
        this.loading = true;
      } else {
        this.data.showSinglePayment = true;
      }
    },

    pay() {
      this.$refs.paymentRef.submit();
    },
    formatDate(timeslotDate, timeSlotTime) {
      let day = timeslotDate + " " + timeSlotTime;
      if (this.$dayjs(day).isValid()) {
        let beginFormat = this.$dayjs(day).format("dddd D MMMM");
        return `${beginFormat} `;
      }
      return day;
    }
  }
};
</script>
<style scoped lang="scss">
/* Variables */
* {
  box-sizing: border-box;
}

.cart {
  position: relative;
  padding-bottom: 56px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

.hidden {
  display: none;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}
</style>
