<template>
  <div
    class="payment-invoice flex-column"
    v-if="billingData && billingData.person"
  >
    <h3>Ti serve la fattura?</h3>
    <v-switch
      v-model="needInvoice"
      inset
      :label="$t('paymentInvoice.wantInvoice')"
      @change="handleInvoiceChange"
    ></v-switch>
    <div v-if="needInvoice">
      <v-row
        class="d-flex w-100"
        v-if="!addNewPersonFiscal && fiscalPersons.length"
      >
        <v-col cols="10" :sm="fastPayment ? 10 : 4">
          <v-select
            ref="fiscalPerson"
            v-model="fiscalPerson"
            :placeholder="$t('paymentInvoice.selectAddress')"
            required
            :rules="[requiredRules()]"
            :items="fiscalPersons"
            item-text="text"
            item-value="value"
            hide-details
            single-line
            dense
            outlined
            @change="setFiscalPerson"
            :error="fiscalPersonIncomplete"
          ></v-select>
        </v-col>
        <v-col cols="2" :sm="fastPayment ? 2 : 1">
          <v-btn
            small
            class="delete-person-fiscal-btn w-100 d-block"
            color="secondary"
            height="40"
            depressed
            :disabled="!fiscalPerson"
            @click="deletePersonFiscal"
          >
            <v-icon size="20" class="mr-1">$delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <!-- <v-row v-if="fiscalPersonIncomplete">
        <v-col>
          <v-alert type="error" dense>
            {{ $t("paymentInvoice.incomplete") }}
          </v-alert>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="12" class="mt-0 mb-1">
          <v-btn
            v-if="!addNewPersonFiscal"
            large
            class="add-person-fiscal-btn"
            color="primary"
            height="40"
            depressed
            @click="addNewAddress"
          >
            <v-icon size="20" class="mr-1">$plus</v-icon>
            {{ $t("paymentInvoice.addAddressBtn") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-card
        class="payment-invoice-card pa-6 mb-5"
        v-if="needInvoice && addNewPersonFiscal"
        :loading="loading"
      >
        <template slot="progress">
          <v-progress-linear height="10" indeterminate></v-progress-linear>
        </template>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12 " class="pb-0">
              <v-radio-group
                v-model="billingData.person.personTypeId"
                mandatory
                row
                @change="resetAndValidate($refs.form)"
              >
                <v-radio label="PERSONA FISICA" :value="1"></v-radio>
                <v-radio label="SOCIETA'" :value="2"></v-radio>
                <v-radio label="DITTA INDIVIDUALE" :value="3"></v-radio>
                <v-radio label="ENTE PUBBLICO" :value="4"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <template v-if="billingData.person.personTypeId == 1">
            <v-row>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="billingData.person.firstName"
                  label="Nome *"
                  outlined
                  dense
                  @blur="$refs.form.validate()"
                  required
                  :rules="[requiredValue('Campo obbligatorio')]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="billingData.person.lastName"
                  label="Cognome *"
                  outlined
                  dense
                  @blur="$refs.form.validate()"
                  required
                  :rules="[requiredValue('Campo obbligatorio')]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  ref="vatFiscalCodeP"
                  v-model="billingData.person.vatFiscalCode"
                  label="Codice Fiscale *"
                  :rules="cfRules"
                  outlined
                  dense
                  @input="validateField('vatFiscalCodeP')"
                  @blur="$refs.form.validate()"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="billingData.billingAddress.address1"
                  :rules="addressRules"
                  label="Indirizzo *"
                  required
                  outlined
                  @blur="$refs.form.validate()"
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="3" class="pb-0">
                <v-text-field
                  v-model="billingData.billingAddress.addressNumber"
                  label="Civico *"
                  :rules="civicRules"
                  required
                  outlined
                  @blur="$refs.form.validate()"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" class="pb-0">
                <v-text-field
                  v-model="billingData.billingAddress.postalcode"
                  label="Cap *"
                  :rules="[requiredValue('Campo obbligatorio')]"
                  required
                  outlined
                  @blur="$refs.form.validate()"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="billingData.billingAddress.city"
                  label="Comune *"
                  :rules="[requiredValue('Campo obbligatorio')]"
                  required
                  outlined
                  dense
                  @blur="$refs.form.validate()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-model="selectedProvince"
                  :items="provinceList"
                  item-text="name"
                  item-value="name"
                  label="Provincia *"
                  :rules="[requiredValue('Campo obbligatorio')]"
                  required
                  outlined
                  dense
                  return-object
                  @change="selectProvince"
                  @blur="$refs.form.validate()"
                ></v-select>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="billingData.person.company"
                  label="Ragione sociale *"
                  :rules="[requiredValue('Campo obbligatorio')]"
                  required
                  outlined
                  dense
                  @blur="$refs.form.validate()"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  ref="vatFiscalCodeL"
                  v-if="billingData.person.personTypeId == 3"
                  v-model="billingData.person.vatFiscalCode"
                  label="Codice fiscale *"
                  :rules="vatFiscalRulesIndividual"
                  required
                  outlined
                  dense
                  @input="validateField('vatFiscalCodeL')"
                  @blur="$refs.form.validate()"
                ></v-text-field>
                <v-text-field
                  v-if="
                    billingData.person.personTypeId == 2 ||
                      billingData.person.personTypeId == 4
                  "
                  ref="vatFiscalCode"
                  v-model="billingData.person.vatFiscalCode"
                  label="Codice fiscale azienda*"
                  outlined
                  dense
                  required
                  :rules="vatFiscalRules"
                  @input="validateField('vatFiscalCode')"
                  @blur="$refs.form.validate()"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-if="
                    billingData.person.personTypeId == 2 ||
                      billingData.person.personTypeId == 4
                  "
                  ref="vatCode"
                  v-model="billingData.person.vatCode"
                  label="Partita IVA *"
                  :rules="vatCodeRules"
                  required
                  outlined
                  dense
                  @input="validateField('vatCode')"
                  @blur="$refs.form.validate()"
                ></v-text-field>
                <v-text-field
                  v-if="billingData.person.personTypeId == 3"
                  ref="vatCode"
                  v-model="billingData.person.vatCode"
                  label="Partita IVA *"
                  :rules="vatCodeRulesRequired"
                  required
                  outlined
                  dense
                  @input="validateField('vatCode')"
                  @blur="$refs.form.validate()"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" class="pb-0">
                <v-text-field
                  v-model="billingData.billingAddress.address1"
                  :rules="addressRules"
                  label="Indirizzo *"
                  required
                  outlined
                  dense
                  @blur="$refs.form.validate()"
                />
              </v-col>
              <v-col cols="6" sm="3" class="pb-0">
                <v-text-field
                  v-model="billingData.billingAddress.addressNumber"
                  label="Civico *"
                  :rules="civicRules"
                  required
                  outlined
                  @blur="$refs.form.validate()"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" class="pb-0">
                <v-text-field
                  v-model="billingData.billingAddress.postalcode"
                  label="Cap *"
                  :rules="[requiredValue('Campo obbligatorio')]"
                  required
                  @blur="$refs.form.validate()"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="billingData.billingAddress.city"
                  label="Comune *"
                  :rules="[requiredValue('Campo obbligatorio')]"
                  required
                  @blur="$refs.form.validate()"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="selectedProvince"
                  :items="provinceList"
                  item-text="name"
                  item-value="name"
                  label="Provincia *"
                  :rules="[requiredValue('Campo obbligatorio')]"
                  required
                  outlined
                  dense
                  @blur="$refs.form.validate()"
                  return-object
                  @change="selectProvince"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-select
                  v-model="billingData.billingAddress.country.countryId"
                  label="Nazione *"
                  item-text="name"
                  item-value="countryId"
                  placeholder="Nazione *"
                  outlined
                  dense
                  @blur="$refs.form.validate()"
                  elevation="0"
                  :items="customCountries"
                  required
                  disabled
                />
              </v-col>
            </v-row>
          </template>

          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                color="primary"
                text
                x-large
                class="mr-2"
                @click.prevent="goBackAndValidate()"
              >
                {{ $t("paymentInvoice.addExistentAddressBtn") }}
              </v-btn>
              <v-btn
                type="button"
                x-large
                color="primary"
                depressed
                :disabled="disabledUpdate"
                @click.prevent="createPersonFiscal"
              >
                {{ $t("paymentInvoice.confirmAddressBtn") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </div>
    <div class="py-3">
      <h3>{{ $t("payment.insertPhoneTitle") }}</h3>
      <p>{{ $t("payment.insertPhoneDesc") }}</p>
      <v-form ref="PhoneForm" v-model="validPP">
        <v-row no-gutters>
          <!-- PREFIX-->
          <v-col cols="2" :sm="fastPayment ? 2 : 1">
            <v-text-field
              v-model="billingDataPhone.prefix"
              class="text-center"
              :placeholder="billingDataPhone.prefix"
              disabled
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col
            cols="10"
            :sm="fastPayment ? 9 : 4"
            class="pr-md-2 d-flex flex-row"
          >
            <v-text-field
              dense
              v-model="billingDataPhone.phone"
              :label="$t('payment.phone')"
              outlined
              required
              :rules="isMobilePhoneRules"
              @blur="validate()"
            />
            <v-btn
              color="primary lighten-1"
              class="ml-1"
              elevation="0"
              height="40"
              large
              :disabled="!validPP"
              @click="updatePhoneDetails"
            >
              {{ $t("payment.phoneSave") }}
            </v-btn>
          </v-col>
        </v-row>

        <router-link
          :to="{ name: 'Page', params: { pageName: 'privacy-policy' } }"
          class="privacy"
          target="_blank"
        >
          {{ $t("payment.privacyLink") }}
        </router-link>
        <div class="d-flex align-center">
          <v-checkbox
            class="mt-2 conditions text-uppercase"
            hide-details
            required
            v-model="sellCondition"
            false-value="false"
            true-value="true"
          >
          </v-checkbox>
          <i18n path="payment.salesCondition" tag="span" class="mt-3">
            <template v-slot:link>
              <router-link
                :to="{
                  name: 'Page',
                  params: { pageName: 'condizioni-di-vendita' }
                }"
                target="_blank"
              >
                <span class="primary--text text--lighten-1">
                  {{ $t("payment.salesConditionLink") }}</span
                >
              </router-link>
            </template>
          </i18n>
        </div>
      </v-form>
    </div>
  </div>
</template>
<style lang="scss">
.payment-invoice {
  .v-autocomplete {
    .v-input__icon {
      display: none;
    }
  }
}
.delete-person-fiscal-btn {
  .v-btn__content {
    margin-left: 4px;
  }
}
</style>
<script>
import { mapActions, mapGetters, mapState } from "vuex";

import RegistrationService from "~/service/userService";
import { req } from "../../validator/commons";
import { mapCartInfo } from "~/service/ebsn";
import EurospinCustomService from "@/service/eurospinCustomService";

import debounce from "lodash/debounce";
import {
  requiredValue,
  isEmail,
  isCF,
  isCfIva,
  isMobilePhoneStrict,
  maxLength
} from "@/validator/validationRules";

export default {
  name: "PaymentInvoice",
  props: {
    full: { type: Boolean, required: false, default: false },
    fastPayment: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      loading: false,
      fiscalPersonIncomplete: true,
      valid: false,
      validPP: false,
      validFormEdit: false,
      lazy: false,
      dirty: false,
      address: null,
      customCountries: global.config.customCountries,
      isMobilePhoneRules: [isMobilePhoneStrict(), requiredValue()],
      phoneConfirmed: false,
      billingData: {},
      fiscalPerson: null,
      fiscalPersons: [],
      provinceList: [],
      addNewPersonFiscal: false,
      billingDataPhone: {
        phone: null,
        prefix: "+39"
      },
      requiredValue: requiredValue,
      requiredRules: requiredValue,
      cfRules: [requiredValue(), isCF()],
      emailPersonRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      emailRules: [
        (this.billingData && !this.billingData.person.emailCertified) ||
          isEmail()
      ],
      addressRules: [requiredValue("Campo obbligatorio"), maxLength(60)],
      civicRules: [requiredValue("Campo obbligatorio"), maxLength(8)],
      vatFiscalRules: [
        v => {
          let _t1 = this;
          if (v) {
            return (
              !req(v) ||
              /^[0-9]{11}$/.test(v) ||
              "Il Codice Fiscale deve avere 11 cifre"
            );
          } else if (_t1.billingData && !_t1.billingData.person.vatCode) {
            return "Partita Iva o Codice Fiscale obbligatori";
          } else {
            return true;
          }
        }
      ],
      vatFiscalRulesIndividual: [
        requiredValue("Campo obbligatorio"),
        v => {
          // let _t1 = this;
          if (v) {
            return (
              !req(v) ||
              /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/.test(
                v.toUpperCase()
              ) ||
              "Formato non corretto"
            );
          } else {
            return true;
          }
        }
      ],
      vatCodeRules: [
        v => {
          let _t1 = this;
          if (v) {
            return (
              !req(v) ||
              /^[0-9]{11}$/.test(v) ||
              "La Partita Iva deve avere 11 cifre"
            );
          } else if (_t1.billingData && !_t1.billingData.person.vatFiscalCode) {
            return "Partita Iva o Codice Fiscale obbligatori";
          } else {
            return true;
          }
        }
      ],
      vatCodeRulesRequired: [
        requiredValue("Campo obbligatorio"),
        v => {
          if (v) {
            return (
              !req(v) ||
              /^[0-9]{11}$/.test(v) ||
              "La Partita Iva deve avere 11 cifre"
            );
          } else {
            return true;
          }
        }
      ],
      emailPecRules: [
        v => {
          if (v) {
            return (
              !req(v) ||
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) ||
              "Digitare una e-mail valida"
            );
          } else if (this.billingData && !this.billingData.person.vatSdiCode) {
            return "PEC o SDI obbligatori";
          } else {
            return true;
          }
        }
      ],
      sdiRules: [
        v => {
          if (v) {
            return /^[A-Z0-9]{6,7}$/.test(v) || "Codice SDI non valido";
          } else if (
            this.billingData &&
            !this.billingData.person.emailCertified
          ) {
            return "PEC o SDI obbligatori";
          } else {
            return true;
          }
        }
      ],
      cfIvaRules: [requiredValue(), isCfIva()],
      selectedProvince: null,
      selectedCity: null,
      selectedAddress: null,
      city: null,
      address1: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapCartInfo({ sellCondition: "accept_sell_condition" }),
    ...mapGetters({
      needInvoice: "cart/needInvoice",
      warehouseId: "cart/getWarehouseId",
      serviceId: "cart/getSelectedService"
    }),
    disabledUpdate() {
      return !this.valid;
    }
  },
  methods: {
    changed() {
      this.dirty = true;
      if (this.phoneConfirmed) {
        this.validate;
      }
    },
    getProvinces(provinceId, homeDeliveryOnly) {
      let _this = this;
      EurospinCustomService.getProvinces(provinceId, homeDeliveryOnly).then(
        results => {
          _this.provinceList = results;
        }
      );
    },
    addNewAddress() {
      this.addNewPersonFiscal = true;
      this.resetFormFields();
    },
    validate() {
      let _this = this;

      _this.validPP = _this.$refs.PhoneForm.validate();
      if (
        (_this.billingData.person.personTypeId == "2" ||
          _this.billingData.person.personTypeId == "4") &&
        _this.$refs.vatFiscalCode
      ) {
        _this.validPP = _this.validPP && _this.$refs.vatFiscalCode.validate();
      }

      _this.fiscalPersonIncomplete =
        _this.needInvoice && !this.$refs.fiscalPerson?.validate();

      let isValid =
        _this.validPP &&
        _this.phoneConfirmed &&
        !_this.fiscalPersonIncomplete &&
        _this.sellCondition === "true";
      // console.log("bbb 1st", isValid);
      if (isValid && _this.needInvoice && !_this.fiscalPerson) {
        isValid = false;
      }
      // console.log("bbb 2nd", isValid);

      _this.$emit("billingFormCompleted", isValid);
    },

    async createPersonFiscal() {
      // if (this.$refs.form.validate()) {
      this.selectedCountry = this.customCountries.filter(country => {
        return (
          country.countryId == this.billingData.billingAddress.country.countryId
        );
      });
      this.billingData.billingAddress.country.countryId = this.selectedCountry[0].countryId;
      this.billingData.billingAddress.country.iso = this.selectedCountry[0].iso;
      this.billingData.billingAddress.country.name = this.selectedCountry[0].name;
      this.billingData.person.vatSdiCode = "0000000";
      const res = await RegistrationService.insertFiscalPerson(
        this.billingData
      );
      if (res.response?.status === 0 && res.data) {
        this.billingData.person = res.data;
        await this.getFiscalPersons();
        this.addNewPersonFiscal = !this.addNewPersonFiscal;
        this.fiscalPerson = res.data.personFiscalId;
        await this.setFiscalPerson();
        this.resetFormFields();
      }
      //   this.dirty = false;
      // }
    },
    goBackAndValidate() {
      this.addNewPersonFiscal = !this.addNewPersonFiscal;
      this.fiscalPersonIncomplete = this.needInvoice && !this.fiscalPerson;
    },
    async updatePhoneDetails() {
      if (this.$refs.PhoneForm.validate()) {
        this.billingData.phone = this.billingDataPhone.phone;
        this.billingData.prefix = this.billingDataPhone.prefix;
        await RegistrationService.updateUserDetail(this.billingData);
        this.phoneConfirmed = true;
        this.validate();
      }
    },
    async setFiscalPerson() {
      this.personFiscalId = this.fiscalPerson;
      await this.setCartInfo({ PERSON_FISCAL_ID: this.fiscalPerson });
      this.validate();
    },
    resetAndValidate(form) {
      this.$nextTick(() => {
        if (form) {
          form.validate();
        }
      });
    },
    resetFormFields() {
      this.billingData.person.firstName = "";
      this.billingData.person.lastName = "";
      this.billingData.person.vatFiscalCode = "";
      this.billingData.person.company = "";
      this.billingData.person.vatCode = "";
      this.billingData.billingAddress.name = "";
      this.billingData.billingAddress.address1 = "";
      this.billingData.billingAddress.addressName = "";
      this.selectedProvince = null;
      this.billingData.billingAddress.province = "";
      this.billingData.billingAddress.city = "";
      this.billingData.billingAddress.postalcode = "";
      this.billingData.billingAddress.addressNumber = "";
      this.billingData.prefix = "+39";
      this.billingDataPhone.phone = this.billingData.phone;
    },

    async getUserDetail() {
      let _this = this;
      let res = await RegistrationService.getUserDetail();
      _this.billingData = res;
      if (res.phone && res.phone.length > 0) {
        this.phoneConfirmed = true;
      } else {
        this.phoneConfirmed = false;
      }
      this.billingData = res;
      this.resetFormFields();
    },
    async getFiscalPersons() {
      let res = await RegistrationService.getFiscalsPersonsList();
      if (res?.personFiscals) {
        this.fiscalPersons = [];
        for (const fp of res?.personFiscals) {
          if (fp.personTypeId === "1") {
            this.fiscalPersons.push({
              text: `${fp.firstName} ${fp.lastName}`,
              value: fp.personFiscalId
            });
          } else {
            this.fiscalPersons.push({
              text: `${fp.company}`,
              value: fp.personFiscalId
            });
          }
        }
      }
    },
    validateField(input) {
      if (this.$refs.vatCode && input == "vatCode") {
        if (this.$refs.vatFiscalCode) {
          this.$refs.vatFiscalCode.validate();
        }
        if (this.$refs.vatFiscalCodeL) {
          this.$refs.vatFiscalCodeL.validate();
        }
        if (this.$refs.vatFiscalCodeP) {
          this.$refs.vatFiscalCodeP.validate();
        }
      } else if (
        (this.$refs.vatFiscalCode && input == "vatFiscalCode") ||
        (this.$refs.vatFiscalCodeL && input == "vatFiscalCodeL") ||
        (this.$refs.vatFiscalCodeP && input == "vatFiscalCodeP")
      ) {
        if (this.$refs.vatCode) {
          this.$refs.vatCode.validate();
        }
      }
    },
    async deletePersonFiscal() {
      if (this.fiscalPerson) {
        const res = await RegistrationService.deleteFiscalPerson(
          this.fiscalPerson
        );
        if (res) {
          await this.getFiscalPersons();
          await this.setCartInfo({ PERSON_FISCAL_ID: null });
          this.fiscalPerson = null;
          this.validate();
        }
      }
    },
    selectProvince() {
      let _this = this;
      if (this.selectedProvince.name) {
        _this.billingData.billingAddress.province = this.selectedProvince.name;
      }
    },
    fetchCityList: debounce(function(val) {
      let _this = this;
      this.getCityList(val, _this.selectedProvince.Id);
    }, 400),
    selectCity(city) {
      let _this = this;
      if (city.Id != null) {
        _this.billingData.billingAddress.city = city.Nome;
        _this.resetAddress();
      } else if (this.cityList.length > 0) {
        _this.selectedCity = this.cityList[0];
        this.selectCity(_this.selectedCity);
      }
    },
    resetCity() {
      let _this = this;
      _this.selectedCity = null;
      _this.billingData.billingAddress.city = null;
      _this.cityList = [];
    },
    selectAddress(address) {
      let _this = this;
      if (address.Id) {
        this.billingData.billingAddress.address1 = address.Nome;
        this.postalcodeList = address.elencoCAP;
        if (address.elencoCAP.length == 1) {
          this.billingData.billingAddress.postalcode = address.elencoCAP[0];
        }
      } else if (this.addressList.length > 0) {
        _this.selectedAddress = this.addressList[0];
        this.selectAddress(this.addressList[0]);
      }
    },
    resetAddress() {
      let _this = this;
      _this.selectedAddress = null;
      _this.addressList = [];
      _this.billingData.billingAddress.address1 = null;
    },
    fetchAddressSuggestion: debounce(function(val) {
      this.getAddressList(val, this.selectedCity.Id);
    }, 400),

    async handleInvoiceChange() {
      let _this = this;
      await _this.setCartInfo({ need_invoice: !_this.needInvoice });
      await this.setCartInfo({ PERSON_FISCAL_ID: null });
      //   if (_this.needInvoice) {
      //     await _this.fetchDefaultData();
      //   }
      _this.validate();
    },
    ...mapActions({
      setCartInfo: "cart/setCartInfo"
    })
  },
  watch: {
    address1(val, oldVal) {
      if (val && val.trim().length > 3 && val != oldVal) {
        this.fetchAddressSuggestion(val);
      }
    },
    city(val, oldVal) {
      if (val && val.trim().length > 0 && val != oldVal) {
        this.fetchCityList(val, this.provinceId);
      }
    },
    sellCondition() {
      this.validate();
    },
    // eslint-disable-next-line no-unused-vars
    fiscalPerson(val, oldVal) {
      if (val) {
        this.validate();
      }
    }
  },
  async mounted() {
    await this.getUserDetail();
    await this.getFiscalPersons();
    await this.getProvinces(1, false);

    // if (this.needInvoice) {
    this.validate();
    // }
    if (typeof this.sellCondition === "undefined") {
      this.sellCondition = "false";
    }
  }
};
</script>
