<template>
  <v-layout column>
    <!--<category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />-->

    <v-row
      no-gutters
      :justify="$vuetify.breakpoint.xs ? 'end' : 'space-between'"
      v-if="!showNoProductCard"
      align="center"
    >
      <Breadcrumb :items="breadcrumb" />
      <v-btn
        depressed
        class="back"
        :to="{ path: $routerHistory.previous().path }"
        ><v-icon>$chevronLeft</v-icon> {{ $t("navbar.back") }}</v-btn
      >
    </v-row>

    <template v-if="!loading">
      <v-container
        fluid
        v-if="product && product.productId"
        class="product-detail pa-0 mt-3 mt-md-0"
      >
        <ProductDetail
          v-if="product.productId"
          :product="product"
          :selectedImage="selectedImage"
          :key="'product_detail_' + key"
          @selectImage="selectImage"
        />
        <ProductDescription
          v-if="product.productId"
          :product="product"
          :key="'product_description_' + key + '_' + product.productId"
          :productMetadata="product.metaData"
          :ingredientList="product.ingredientList"
        />

        <div class="product-detail-slider-container mt-6">
          <!-- <div class="product-detail-slider">
          <v-container fluid>
            <ProductListSlider
              :layout="220"
              :limit="12"
              v-if="product.productId"
              :productId="product.productId"
              :key="product.productId + '_1'"
              title="Ti potrebbero interessare anche:"
              :paginationClass="'swiper-pagination-interesse'"
              :showArrows="$vuetify.breakpoint.smAndUp"
            />
          </v-container>
        </div>
        <div class="product-detail-slider">
          <v-container fluid>
            <ProductListSlider
              :layout="223"
              :limit="12"
              v-if="product.productId"
              :productId="product.productId"
              :key="product.productId + '_2'"
              title="Suggeriti per te:"
              :paginationClass="'swiper-pagination-suggeriti'"
              :showArrows="$vuetify.breakpoint.smAndUp"
            />
          </v-container>
        </div> -->

          <!-- default layout 220 -> Ti potrebbero interessare anche: -->
          <category-block
            :target="category"
            :productId="product.productId"
            :category-id="product.categoryId"
            position="product1"
            class="category-block category-block-1"
          />

          <!-- default: crm layout 223 -> Suggeriti per te: -->
          <category-block
            :target="category"
            :productId="product.productId"
            :category-id="product.categoryId"
            position="product2"
            class="category-block category-block-2"
          />
        </div>
      </v-container>
      <v-container v-else>
        <NotFound />
      </v-container>
    </template>

    <category-block
      :target="category"
      position="position2"
      :productId="product.productId"
      :category-id="product.categoryId"
      class="category-block category-block-2"
    />
  </v-layout>
</template>

<script>
import ProductDescription from "@/components/product/ProductDescription";
import NotFound from "@/components/NotFound";
import ProductDetail from "@/components/product/ProductDetail";
import Breadcrumb from "@/components/navigation/Breadcrumb";

import categoryMixin from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";

import ProductService from "~/service/productService";
import analyticsService from "~/service/analyticsService";

import join from "lodash/join";
import compact from "lodash/compact";
import clone from "lodash/clone";
import get from "lodash/get";

export default {
  name: "Product",
  mixins: [categoryMixin, deliveryReactive],
  data() {
    return {
      showNoProductCard: null,
      product: {
        productId: null,
        name: "",
        shortDescr: "",
        description: ""
      },
      loading: false,
      selectedImage: null,
      key: 1
    };
  },
  components: {
    Breadcrumb,
    ProductDescription,
    ProductDetail,
    NotFound
  },
  computed: {
    getFullName() {
      return join(
        compact([this.product.name, this.product.shortDescr.toLowerCase()]),
        " "
      );
    },
    vBreadcrumb() {
      let b = clone(this.breadcrumb);
      if (this.product) {
        b.push({
          to: {
            name: "Product",
            params: {
              volantino: this.product.name
            }
          },
          text: this.product.name,
          exact: true
        });
      }
      return b;
    }
  },
  methods: {
    async getProduct() {
      this.loading = true;
      var data = await ProductService.getProductBySlug(this.slug);
      if (data) {
        this.product = data;
        if (this.product.categoryId) {
          this.setCategory(this.product.categoryId);
        }

        if (this.product && this.product.media && this.product.media.length) {
          this.selectImage(this.product.media[0]);
        }
      }
      this.loading = false;
    },
    async setProduct(product) {
      // var data = await ProductService.getProductBySlug(this.slug);
      if (product) {
        this.product = product;
        if (this.product.categoryId) {
          this.setCategory(this.product.categoryId);
        }
        // if (this.product.categoryId) {
        //   this.setCategory(this.product.categoryId);
        // }
        if (this.product && this.product.media && this.product.media.length) {
          this.selectImage(this.product.media[0]);
        }
        analyticsService.viewProductDetail(this.product);
      }
      this.loading = false;
    },
    async setCategory(categoryId) {
      this.$store.dispatch("category/setCategory", {
        slug: categoryId,
        reset: false
      });
    },
    async selectImage(image) {
      this.selectedImage = image;
      this.selectedImage.thumb = image.medium;
    },
    async reload() {
      // await this.resetFilters(this.category.slug);
      console.log("reload");
      await this.setProduct(this.$route.params.product);
      this.key += 1;
    }
  },
  async mounted() {
    this.slug = this.$route.params.productSlug;
    this.setProduct(this.$route.params.product);
    // await this.getProduct();
    // analyticsService.viewProductDetail(this.product);
  },
  watch: {
    async $route(to) {
      // if (to.params.slug != this.slug) {
      //   this.slug = to.params.slug;
      //   await this.getProduct();
      //   analyticsService.viewProductDetail(this.product);
      // }
      if (to.params.productSlug != this.slug) {
        this.slug = to.params.productSlug;
        this.setProduct(this.$route.params.product);
        // await this.getProduct();
        // analyticsService.viewProductDetail(this.product);
      }
    }
  },
  metaInfo() {
    return {
      title: this.showNoProductCard
        ? "Eurospin"
        : get(this.product, "metaData.product_seo.SEO_TITLE", this.getFullName),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.product,
            "metaData.product_seo.SEO_DESCRIPTION",
            "Acquista subito online " +
              this.getFullName +
              " sul nostro sito e ritira gratis nei punti vendita in cui è attivo il servizio."
          )
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: get(this.product, "metaData.product_seo.SEO_KEYWORDS")
        }
      ]
    };
  }
};
</script>
