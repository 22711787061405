var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"payment mt-4"},[_c('PaymentStepper',{staticClass:"d-flex d-md-none mb-2",attrs:{"step":2}}),_c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"summary-cards d-flex flex-column mt-4 mt-sm-0",attrs:{"cols":"12","md":"3","order":"0","order-md":"1"}},[_c('div',{staticClass:"filter-sticky-column"},[_c('CartInfoAddressCard',{staticClass:"mb-2 order-1 order-md-0",attrs:{"shippingAddress":_vm.cart.shippingAddress,"editable":false,"isCheckout":false,"small":true},on:{"edit":_vm.openAddressSelector}}),_c('CartInfoTimeslotCard',{staticClass:"mb-2 order-2 order-md-1",attrs:{"shippingAddress":_vm.cart.shippingAddress,"timeslot":_vm.cart.timeslot,"editable":false,"isCheckout":false,"small":true},on:{"edit":_vm.openTimeslotSelector}}),_c('CheckoutSummary',{staticClass:"order-0 order-md-2 mb-6",attrs:{"orderCart":_vm.cart}})],1)]),_c('v-col',{staticClass:"cart-info pa-0 pa-sm-3 pr-sm-9",attrs:{"cols":"12","order":"1","order-md":"0","md":"9"}},[_c('PaymentStepper',{staticClass:"d-none d-md-flex",attrs:{"step":2}}),_c('PaymentInvoice',{staticClass:"mt-4",on:{"billingFormCompleted":_vm.billingFormCompleted}}),_c('PaymentTypeList',{attrs:{"options":{
              showRememberCard: false,
              showPaymentMethodImage: true,
              showCardLogos: true,
              orderAmount: _vm.cart.totalPrice,
              showPrebilledAmountInfoText: true,
              showPrebilledAmountHtml: true,
              showAddNewCardWarning: false,
              confirm: { color: 'secondary' },
              back: {
                class: 'back-btn'
              },
              titleStyle: 'grey--text text--darken-2 text-body-1 mb-2'
            },"isPaymentDisabled":!_vm.billingFormCompletedInternal,"paymentNameToPaymentInfo":true}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }